import React from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  ListItem,
  Checkbox,
} from '@material-ui/core'
import clsx from 'clsx'
import { ExpandLess, ExpandMore, KeyboardArrowRight } from '@material-ui/icons'

type Tprops = {
  taxon: any
  checked: any
  handleToggle: any
  index: any
  position: number
  hasCheckbox?: boolean
  open: boolean
  name: string
  isList?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterListItem: {
      cursor: 'pointer',
      height: 35,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      justifyContent: 'space-between',
      fontSize: 14,
      borderBottom: `1px solid ${theme.extraColors.greyLight}`,
    },
    filterListItemActive: {
      //pointerEvents: 'none',
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    checkBox: {
      lineHeight: '40px',
    },
    active: {
      fontWeight: 700,
    },
  })
)

const TaxonListItem = ({
  taxon,
  handleToggle,
  checked,
  index,
  position,
  name,
  isList,
  hasCheckbox,
  open,
}: Tprops) => {
  const classes = useStyles()

  return (
    <>
      <ListItem
        className={clsx(
          classes.filterListItem,
          checked === index && classes.filterListItemActive
        )}
        onClick={() =>
          taxon?.children?.length > 0
            ? handleToggle(index, taxon, position)
            : checked !== index && handleToggle(index, taxon, position)
        }
        key={index}
      >
        <div
          className={clsx(
            classes.checkBox,
            checked === index && (classes.active, 'filter__list_item--active')
          )}
        >
          {hasCheckbox && (
            <Checkbox
              edge="start"
              checked={isList ? checked : checked === index}
              tabIndex={-1}
              disableRipple
            />
          )}
          {name}
        </div>
        {taxon?.children?.length > 0 &&
          hasCheckbox &&
          (open ? <ExpandLess /> : <ExpandMore />)}
        {!hasCheckbox && taxon?.children?.length > 0 && <KeyboardArrowRight />}
      </ListItem>
    </>
  )
}

export default TaxonListItem
