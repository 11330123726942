import React from 'react'
import { ListItem, Collapse, List, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { useStyles as useAttributesFiltersStyles } from './AttributesFilters'
import AttributeListItem from './AttributeListItem'
import { SelectAttribute } from '../../typings/filters'

type Tprops = {
  attributeFilter: SelectAttribute
  handleExtend: (attributeFilter?: SelectAttribute, type?: string) => void
  open: any
  selectedAttributes: any[]
  handleToggle: (code: string, key: string[]) => void
}

const RenderSelectFilter = ({
  attributeFilter,
  handleExtend,
  open,
  selectedAttributes,
  handleToggle,
}: Tprops) => {
  const classes = useAttributesFiltersStyles()
  const attributeType = attributeFilter?.type
  const attributeCode = attributeFilter?.code
  const sortArrayAlphanumeric = (arr) => {
    return arr.sort((a, b) => {
      return a.value.localeCompare(b.value, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    })
  }
  return (
    <div key={attributeType}>
      <ListItem
        className={classes.filterListItem}
        onClick={() => handleExtend(attributeFilter)}
      >
        <Typography
          variant={'body1'}
          component={'div'}
          className={classes.attributeTitle}
        >
          {attributeFilter.name}
        </Typography>
        {open.find((opened) => opened.value === attributeCode)?.open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItem>
      <Collapse
        in={open.find((opened) => opened.value === attributeCode)?.open}
        timeout="auto"
        unmountOnExit
        className={classes.filterListContent}
      >
        <List component="div" disablePadding>
          {sortArrayAlphanumeric(attributeFilter.choices)?.map(
            (choice, index) => {
              const currentAttributeCat = selectedAttributes
                ?.find((selected) => selected.code === attributeFilter.code)
                ?.values?.find((value) => value === choice.key)
              return (
                <div key={index}>
                  <AttributeListItem
                    code={attributeFilter.code}
                    attributeKey={choice.key}
                    handleToggle={handleToggle}
                    index={index}
                    name={choice.value}
                    checked={typeof currentAttributeCat !== 'undefined'}
                  />
                </div>
              )
            }
          )}
        </List>
      </Collapse>
    </div>
  )
}

export default RenderSelectFilter
