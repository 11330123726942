export interface Filter {
  code: string
  values: string[]
}

export interface PriceAttribute {
  code: PriceAttributeCode.PRICE
  currency: string
  maximum: number
  minimum: number
  type: AttributeType.PRICE | AttributeType.TAKE_BACK_PRICE
}

export interface SelectAttribute {
  code: string
  name: string
  choices: { key: string; value: string }[]
  type: AttributeType.SELECT
}

export interface CheckboxAttribute {
  code: string
  type: AttributeType.CHECKBOX
}

export enum PriceAttributeCode {
  PRICE = 'price',
}

export enum AttributeType {
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  PRICE = 'price',
  TAKE_BACK_PRICE = 'takeBackPrice',
}

export type Attribute = SelectAttribute | PriceAttribute | CheckboxAttribute
