import React, { useEffect, useState } from 'react'
import {
  Collapse,
  Drawer,
  Fade,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import Button from '../Button/Button'
import { connect } from 'react-redux'
import {
  getProductsByTaxonsSlug,
  setCurrentSlug,
} from '@/redux/actions/products'
import { useRouter } from 'next/router'
import CMSText from '../Global/CMSText'
import AttributesFilters from './AttributesFilters'
import { getTaxonsAttributes, getTaxonsSizes } from '@/redux/actions/taxons'
import Loader from '../Loader/Loader'
import SizesFilters from './SizesFilters'
import {
  ExpandLess,
  ExpandMore,
  KeyboardArrowLeft,
  Replay,
} from '@material-ui/icons'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { RootState } from '@/redux/reducers/rootReducer'
import { PrismicShop } from '@/interfaces/common/prismicShop'
import { GetMapDispatchTypes } from '@/interfaces/common/redux'
import { TFunction } from 'react-i18next'
import { useFilterDrawerStyles } from './FilterDrawer.styles'
import TaxonListItem from './TaxonListItem'
import { isSecondHandOnly } from '@/utils/constants'
import { PrismicTakeBack } from '@/interfaces/common/prismicTakeBack'
import { PriceAttributeCode } from '@/typings/filters'

interface SlugItem {
  slug: string
  name: string
  code: string
}

type Tprops = ReturnType<typeof mapStateToProps> &
  GetMapDispatchTypes<typeof mapDispatchToProps> & {
    isDrawerOpen: boolean
    setIsDrawerOpen: (isDrawerOpen: boolean) => void
    doc: PrismicShop
    currentPage: number
    filterVariantProducts: any
    takebackText: PrismicTakeBack
    t: TFunction
  }

const mapStateToProps = (state: RootState) => ({
  taxons: state.taxons,
  products: state.products,
  parameters: state.parameters,
})

const mapDispatchToProps = {
  getProductsByTaxonsSlug: getProductsByTaxonsSlug,
  getTaxonsAttributes: getTaxonsAttributes,
  getTaxonsSizes: getTaxonsSizes,
  setCurrentSlug: setCurrentSlug,
}

const FilterDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  getTaxonsAttributes,
  getTaxonsSizes,
  takebackText,
  taxons,
  parameters,
  setCurrentSlug,
  currentPage,
  getProductsByTaxonsSlug,
  products,
  doc,
  t,
}: Tprops) => {
  const classes = useFilterDrawerStyles()
  const [selectedTaxons, setSelectedTaxons] = useState([])
  const [activeSlug, setActiveSlug] = useState<SlugItem[]>([])
  const [open, setOpen] = useState(false)
  const [currentSlide, setCurrentSlide] = useState([])
  const [currentSlidePosition, setCurrentSlidePosition] = useState(0)
  const [selectedAttributes, setSelectedAttributes] = useState([])
  const [isSecondHandTaxons, setIsSecondHandTaxons] = useState(
    !!isSecondHandOnly
  )
  const { trackEvent } = useMatomo()
  const router = useRouter()
  let urlCategories: any = router?.query?.slug
  const mainTaxon = taxons?.items?.[0]?.children

  useEffect(() => {
    if (process.env.STORE === 'vaude') {
      if (
        ['second-hand', 'zwiter-hand'].includes(
          mainTaxon[selectedTaxons[0]?.selectedId]?.slug
        )
      ) {
        if (!isSecondHandTaxons) setIsSecondHandTaxons(true)
      } else {
        if (isSecondHandTaxons) setIsSecondHandTaxons(false)
      }
    }
  }, [selectedTaxons])

  // retrieve saved Filters and get Products
  useEffect(() => {
    const filtersStored = localStorage.getItem('Filters')
    if (typeof filtersStored !== 'undefined' && filtersStored !== null) {
      const filtersStoredParsed = JSON.parse(filtersStored)
      if (filtersStoredParsed?.length > 0) {
        setSelectedAttributes(filtersStoredParsed)
      } else {
        localStorage.removeItem('Filters')
      }
    }
  }, [products?.items?.items?.length])

  useEffect(() => {
    handleStateInit()
  }, [taxons?.items])

  const handleStateInit = () => {
    // set selected taxon based on current url
    let mainTaxons = taxons?.items?.[0]?.children || []
    let selectedTaxonIndex = 0
    const selected = []
    const activeSlugArray = []
    const isCurrentSLide = []
    let tempCurrentlocale = 0
    isCurrentSLide.push(mainTaxons)
    if (typeof urlCategories === 'string') {
      urlCategories = [urlCategories]
    }

    // map on the slugs found in url
    urlCategories?.map((categorie, index) => {
      // find index of each slugs in url and push it to the selectedTaxons state to pre-select taxons
      selectedTaxonIndex = mainTaxons.findIndex(
        (maintaxon) => maintaxon.slug === categorie
      )
      // add each slug and name in array
      activeSlugArray.push({
        slug: categorie,
        name: mainTaxons[selectedTaxonIndex]?.name,
        code: mainTaxons[selectedTaxonIndex]?.code,
      })
      // add current selected Taxon in array
      selected.push({ pos: index, selectedId: selectedTaxonIndex })
      // if last url slug has a child, we add the last row with no id selected
      if (
        index === urlCategories.length - 1 &&
        mainTaxons[selectedTaxonIndex]?.children?.length
      ) {
        selected.push({ pos: index + 1, selectedId: null })
      }
      // we update mainTaxon to go to next child and find next slug
      if (mainTaxons[selectedTaxonIndex]?.children.length) {
        tempCurrentlocale = tempCurrentlocale + 1
        isCurrentSLide.push(mainTaxons[selectedTaxonIndex]?.children)
        mainTaxons = mainTaxons[selectedTaxonIndex]?.children
      }
    })
    setCurrentSlidePosition(tempCurrentlocale)
    setCurrentSlide(isCurrentSLide)
    setActiveSlug(activeSlugArray)
    setSelectedTaxons(selected)
  }

  // Function to save or cut the slugs array
  const handleSlug = (position, taxon) => {
    const currentActiveSlug = [...activeSlug]
    // if current Active slug exists, we delete it
    if (currentActiveSlug[position]) {
      currentActiveSlug.splice(position)
    }
    currentActiveSlug.push({
      slug: taxon.slug,
      name: taxon.name,
      code: taxon.code,
    })
    setActiveSlug(currentActiveSlug)
  }

  //handle slide mode
  const handleNextSlide = (taxonId, taxon, position) => {
    handleSlug(position, taxon)
    const tempSelectedTaxons = [...selectedTaxons]
    tempSelectedTaxons[position].selectedId = taxonId
    if (taxon?.children?.length) {
      tempSelectedTaxons.push({ pos: position + 1, selectedId: null })
    }
    setSelectedTaxons(tempSelectedTaxons)
    const tempArray = [...currentSlide]
    if (taxon.children.length > 0) {
      // next taxon has children, change slide and select taxon
      tempArray.splice(position + 1)
      tempArray.push(taxon.children)
      setCurrentSlide(tempArray)
      setCurrentSlidePosition(position + 1)
    } else {
      tempArray.splice(position + 1)
      setCurrentSlide(tempArray)
    }
  }

  // get attributes based on last selected taxon slug
  useEffect(() => {
    if (activeSlug.length > 0) {
      getTaxonsAttributes(activeSlug[activeSlug.length - 1]?.slug)
      getTaxonsSizes(activeSlug[activeSlug.length - 1]?.code)
    }
  }, [activeSlug])

  //handle slide mode
  const handlePrevSlide = () => {
    const currentActiveSlug = [...activeSlug]
    const tempSelectedTaxons = [...selectedTaxons]
    tempSelectedTaxons.splice(currentSlidePosition)
    currentActiveSlug.splice(currentSlidePosition)
    setActiveSlug(currentActiveSlug)
    setSelectedTaxons(tempSelectedTaxons)
    if (currentSlidePosition > 0) {
      setCurrentSlidePosition(currentSlidePosition - 1)
    }
  }

  // handle selection validation and trigger url change
  const handleFilters = () => {
    const asyncFunc = async () => {
      let fullPathname = '/'
      activeSlug.map((slugs) => {
        fullPathname += `${slugs.slug}/`
      })
      trackEvent({
        category: 'Shop',
        action: `User Change category to ${
          activeSlug[activeSlug.length - 1].name
        }`,
      })
      const cleanAttributes = selectedAttributes.filter((attribute) => {
        if (attribute?.values.length === 0) return false
        if (isSecondHandTaxons) {
          return attribute.code !== PriceAttributeCode.PRICE
        } else {
          return !['takeBackPrice', 'productCondition'].includes(attribute.code)
        }
      })
      localStorage.setItem('Filters', JSON.stringify(cleanAttributes))
      const filtersStored = localStorage.getItem('Filters')
      setCurrentSlug(activeSlug[activeSlug.length - 1].slug)
      getProductsByTaxonsSlug(
        activeSlug[activeSlug.length - 1].slug,
        48,
        currentPage,
        JSON.parse(filtersStored)
      )
      await router.push(fullPathname, undefined, { shallow: true })
    }
    asyncFunc()
  }
  const handleResetFilters = () => {
    router.push(taxons?.items[0]?.children[0].slug, undefined, {
      shallow: true,
    })
    setActiveSlug([])
    setSelectedTaxons([])
    setSelectedAttributes([])
    localStorage.removeItem('selectedCategory')
    localStorage.removeItem('Filters')
    handleStateInit()
    getProductsByTaxonsSlug(
      activeSlug[activeSlug.length - 1].slug,
      48,
      currentPage
    )
  }

  const handleExtend = () => {
    setOpen(!open)
  }

  // @TODO: Move it from here
  const FadeList = (slides) => {
    return (
      <>
        <div className={classes.margin}>
          <List component="div" disablePadding>
            {slides.slides.length > 0 &&
              slides?.slides.map((taxon, index) => {
                return (
                  <div key={index}>
                    <TaxonListItem
                      taxon={taxon}
                      handleToggle={handleNextSlide}
                      index={index}
                      name={taxon.name}
                      checked={selectedTaxons[currentSlidePosition]?.selectedId}
                      hasCheckbox={false}
                      position={currentSlidePosition}
                      open={false}
                    />
                  </div>
                )
              })}
          </List>
        </div>
      </>
    )
  }

  return (
    <Drawer
      anchor={'left'}
      open={isDrawerOpen}
      className={classes.drawer}
      onClose={() => setIsDrawerOpen(false)}
    >
      <div className={classes.drawerContainer}>
        <div className={classes.header}>
          {currentSlidePosition !== 0 ? (
            <div className={classes.help} onClick={handlePrevSlide}>
              <KeyboardArrowLeft />{' '}
              <div>
                <CMSText
                  defaultText={t('texts:shop:return')}
                  data={doc?.data?.return}
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <Button onClick={() => setIsDrawerOpen(false)} size="small">
            {' '}
            <CMSText
              defaultText={t('texts:shop:close_button')}
              data={doc?.data?.close_button}
            />
          </Button>
        </div>
        <List disablePadding>
          <div className={classes.filterContainer}>
            {currentSlidePosition !== 0 && (
              <ListItem className={classes.breadcrumb}>
                <Typography
                  component={'span'}
                  variant={'body1'}
                  color={'primary'}
                  className={classes.activeFilter}
                >
                  {activeSlug.length > 0 ? (
                    activeSlug.map((slug, key) => {
                      return (
                        <span className={classes.activeFilterText} key={key}>
                          {slug.name}{' '}
                          {activeSlug.length - 1 !== key && <>/&nbsp;</>}
                        </span>
                      )
                    })
                  ) : (
                    <CMSText
                      defaultText={t('texts:shop:filters_modale_title')}
                      data={doc?.data?.filters_modale_title}
                    />
                  )}
                </Typography>
              </ListItem>
            )}
            {currentSlide[0]?.length > 10 && (
              <ListItem
                className={classes.filterListItem}
                onClick={handleExtend}
              >
                <Typography
                  variant={'body1'}
                  component={'span'}
                  className={classes.taxonTitle}
                >
                  <CMSText
                    defaultText={t('texts:shop:categories_title')}
                    data={doc?.data?.categories_title}
                  />
                </Typography>
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            {currentSlide[0]?.length > 10 ? (
              <Collapse in={open} timeout="auto" unmountOnExit>
                {currentSlide.map((slides, index) => {
                  return (
                    <Fade
                      key={index}
                      //direction="left"
                      in={currentSlidePosition === index}
                      unmountOnExit
                      timeout={{
                        appear: 800,
                        enter: 800,
                        exit: 0,
                      }}
                    >
                      <FadeList slides={slides} />
                    </Fade>
                  )
                })}
              </Collapse>
            ) : (
              <>
                {currentSlide.map((slides, index) => {
                  return (
                    <Fade
                      key={index}
                      //direction="left"
                      in={currentSlidePosition === index}
                      unmountOnExit
                      timeout={{
                        appear: 800,
                        enter: 800,
                        exit: 0,
                      }}
                    >
                      <FadeList slides={slides} />
                    </Fade>
                  )
                })}
              </>
            )}
            <div className={classes.filterContainer}>
              {(taxons?.sizes?.length > 0 ||
                taxons?.attributes?.length > 0) && (
                <Typography className={classes.filterBy_text}>
                  <CMSText
                    defaultText={t('texts:shop:filter_by')}
                    data={doc?.data?.filter_by}
                  />
                </Typography>
              )}
              {taxons?.loadingAttribute || taxons?.loadingSizes ? (
                <div className={classes.loaderContainer}>
                  <Loader />
                </div>
              ) : (
                <>
                  {taxons?.sizes?.length > 0 && (
                    <SizesFilters
                      sizes={taxons?.sizes}
                      setSelectedAttributes={setSelectedAttributes}
                      selectedAttributes={selectedAttributes}
                    />
                  )}
                  {taxons?.attributes?.length > 0 && (
                    <AttributesFilters
                      parameters={parameters}
                      takebackText={takebackText}
                      attributes={taxons?.attributes}
                      setSelectedAttributes={setSelectedAttributes}
                      selectedAttributes={selectedAttributes}
                      t={t}
                      doc={doc}
                      isSecondHandTaxons={isSecondHandTaxons}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </List>
        <div className={classes.footer}>
          <Button
            onClick={() => handleResetFilters()}
            variant={'text'}
            className={classes.resetButton}
            size="small"
          >
            <>
              <Replay />
              <CMSText
                defaultText={t('texts:shop:reset_button')}
                data={doc?.data?.reset_button}
              />
            </>
          </Button>
          <Button
            onClick={() => handleFilters()}
            variant={'contained'}
            color={'primary'}
            className={classes.applyButton}
            size="small"
          >
            <CMSText
              defaultText={t('texts:shop:apply_button')}
              data={doc?.data?.apply_button}
            />
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterDrawer)
