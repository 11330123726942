import React, { useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  Collapse,
  createStyles,
  List,
  ListItem,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import AttributeListItem from './AttributeListItem'
import CMSText from '../Global/CMSText'
import { useTakeback } from '../../hooks/useTakeback'
import PricesFilter from './PricesFilter'
import RenderSelectFilter from './SelectFilter'
import {
  Attribute,
  AttributeType,
  PriceAttribute,
  PriceAttributeCode,
  SelectAttribute,
} from '../../typings/filters'
import { PrismicTakeBack } from '../../interfaces/common/prismicTakeBack'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterListItem: {
      cursor: 'pointer',
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      justifyContent: 'space-between',
      fontSize: 14,
      height: 35,
      borderBottom: `1px solid ${theme.extraColors.greyLight}`,
    },
    filterListContent: {
      backgroundColor: theme.palette.common.white,
      borderBottom: `1px solid ${theme.extraColors.greyLight}`,
    },
    attribute_list: {
      padding: theme.spacing(0, 0, 8, 0),
    },
    sliderContainer: {
      width: '100%',
      padding: theme.spacing(2, 3),
    },
    checkBoxValue: {
      paddingLeft: theme.spacing(2),
      textTransform: 'none',
    },
    attributeTitle: {
      fontSize: 14,
      lineHeight: 1,
    },
  })
)

type Tprops = {
  attributes: Attribute[]
  setSelectedAttributes: (selectedAttributes: any[]) => void
  selectedAttributes: any[]
  takebackText: PrismicTakeBack
  parameters: any
  doc: any
  t: any
  isSecondHandTaxons?: boolean | null
}

const AttributesFilters = ({
  attributes,
  setSelectedAttributes,
  takebackText,
  selectedAttributes,
  doc,
  t,
  parameters,
  isSecondHandTaxons,
}: Tprops) => {
  const classes = useStyles()
  const { productCheckState } = useTakeback()
  const [open, setOpen] = useState([])
  const [priceRange, setPriceRange] = useState({ price: [], takeBackPrice: [] })
  const [qualitiesList, setQualitiesList] = useState(false)
  const noPriceFilter =
    process.env.NO_PRICE_FILTER == 'false' || !process.env.NO_PRICE_FILTER
  const {
    takeBackPriceAttributeInformations,
    priceAttributeInformations,
    selectAttributeInformations,
  } = useMemo(
    () => ({
      takeBackPriceAttributeInformations: attributes.find(
        (attr) => attr.type === AttributeType.TAKE_BACK_PRICE
      ) as PriceAttribute,
      priceAttributeInformations: attributes.find(
        (attr) => attr.type === AttributeType.PRICE
      ) as PriceAttribute,
      selectAttributeInformations: attributes.filter(
        (attr) => attr.type === AttributeType.SELECT
      ) as SelectAttribute[],
    }),
    [attributes]
  )

  useEffect(() => {
    if (selectedAttributes?.length > 0) {
      const tempOpen = []
      const isQualities =
        selectedAttributes.find((el) => el.code === 'productCondition')
          ?.length > 0
      attributes.map((attribute) => {
        const attributeFinded = selectedAttributes.find(
          (selectedAttribute) => selectedAttribute.code === attribute.type
        )
        if (
          attributeFinded?.values?.length > 0 &&
          attribute.code !== 'productCondition'
        ) {
          tempOpen.push({ value: attribute.code, open: true })
        }
        if (attributeFinded && attribute.code === PriceAttributeCode.PRICE) {
          // need to cast attribute as PriceAttribute to access minimum and maximum
          const priceAttribute = attribute as PriceAttribute
          const minimum =
            attributeFinded.values.min < priceAttribute.minimum ||
            attributeFinded.values.min > priceAttribute.maximum
              ? priceAttribute.minimum
              : attributeFinded.values.min
          const maximum =
            attributeFinded.values.max > priceAttribute.maximum ||
            attributeFinded.values.max < priceAttribute.minimum
              ? priceAttribute.maximum
              : attributeFinded.values.max
          // setPriceRange([minimum / 100, maximum / 100])
          setPriceRange((prev) => ({
            ...prev,
            [attribute.type]: [minimum / 100, maximum / 100],
          }))
          tempOpen.push({ value: attribute.code, open: true })
        }
      })
      isQualities && setQualitiesList(true)
      setOpen(tempOpen)
    }
  }, [])

  // extand the attribute list
  const handleExtend = (attributeFilter, type: string) => {
    if (type === AttributeType.PRICE) {
      if (
        priceRange.price.length === 0 &&
        attributeFilter.type === AttributeType.PRICE
      ) {
        setPriceRange((prev) => ({
          ...prev,
          [attributeFilter.type]: [
            attributeFilter.minimum / 100,
            attributeFilter.maximum / 100,
          ],
        }))
      }
      if (
        priceRange.takeBackPrice.length === 0 &&
        attributeFilter.type === AttributeType.TAKE_BACK_PRICE
      ) {
        setPriceRange((prev) => ({
          ...prev,
          [attributeFilter.type]: [
            attributeFilter.minimum / 100,
            attributeFilter.maximum / 100,
          ],
        }))
      }
    }
    const tempOpen = [...open]
    if (tempOpen.find((isopened) => isopened.value === attributeFilter?.code)) {
      tempOpen.splice(
        tempOpen.findIndex(
          (isopened) => isopened.value === attributeFilter?.code
        ),
        1
      )
    } else {
      tempOpen.push({ value: attributeFilter?.code, open: true })
    }
    setOpen(tempOpen)
  }

  // select an attribute in the list
  const handleToggle = (code, key) => {
    const tempSelectedAttributes = [...selectedAttributes]
    const isCodeSelected = tempSelectedAttributes?.find(
      (isSelected) => isSelected.code === code
    )
    if (isCodeSelected) {
      const selectedCategorieIndex = isCodeSelected?.values?.findIndex(
        (value) => value === key
      )
      if (selectedCategorieIndex >= 0) {
        tempSelectedAttributes
          .find((isSelected) => isSelected.code === code)
          .values.splice(selectedCategorieIndex, 1)
      } else {
        tempSelectedAttributes
          ?.find((isSelected) => isSelected.code === code)
          .values?.push(key)
      }
    } else {
      tempSelectedAttributes.push({ code: code, values: [key] })
    }
    setSelectedAttributes(tempSelectedAttributes)
  }

  // set true false for checkbox attributes
  const handleToggleCheckbox = (code) => {
    const tempSelectedAttributes = [...selectedAttributes]
    const isCodeSelected = tempSelectedAttributes?.find(
      (isSelected) => isSelected.code === code
    )
    if (isCodeSelected) {
      const currentValueIndex = tempSelectedAttributes?.findIndex(
        (isSelected) => isSelected.code === code
      )
      tempSelectedAttributes[currentValueIndex].value =
        !tempSelectedAttributes[currentValueIndex].value
    } else {
      tempSelectedAttributes.push({ code: code, value: true })
    }
    setSelectedAttributes(tempSelectedAttributes)
  }

  // set priceRange
  const rangeSelector = (attributeType) => (event, newValue) => {
    setPriceRange((prev) => ({
      ...prev,
      [attributeType]: newValue,
    }))
    const tempSelectedAttributes = [...selectedAttributes]

    const isCodeSelected = tempSelectedAttributes?.find(
      (isSelected) => isSelected.code === attributeType
    )

    if (typeof isCodeSelected !== 'undefined') {
      const selectedCategorieIndex = tempSelectedAttributes.findIndex(
        (isSelected) => isSelected.code === attributeType
      )
      tempSelectedAttributes[selectedCategorieIndex].values = {
        min: newValue[0] * 100,
        max: newValue[1] * 100,
      }
    } else {
      tempSelectedAttributes.push({
        code: attributeType,
        values: { min: newValue[0] * 100, max: newValue[1] * 100 },
      })
    }
    setSelectedAttributes(tempSelectedAttributes)
  }

  const handleExtendQualities = () => {
    setQualitiesList(!qualitiesList)
  }

  return (
    <List className={classes.attribute_list}>
      {selectAttributeInformations !== undefined &&
        selectAttributeInformations.length > 0 &&
        selectAttributeInformations.map((attributeFilter, attributeIndex) => (
          <RenderSelectFilter
            attributeFilter={attributeFilter}
            handleExtend={handleExtend}
            open={open}
            selectedAttributes={selectedAttributes}
            handleToggle={handleToggle}
            key={attributeIndex}
          />
        ))}
      {priceAttributeInformations !== undefined &&
        noPriceFilter &&
        !isSecondHandTaxons && (
          <PricesFilter
            attributeFilter={priceAttributeInformations}
            handleExtend={handleExtend}
            t={t}
            prismicShopData={doc?.data}
            open={open}
            priceRange={priceRange}
            rangeSelector={rangeSelector}
          />
        )}
      {takeBackPriceAttributeInformations !== undefined &&
        noPriceFilter &&
        isSecondHandTaxons && (
          <PricesFilter
            attributeFilter={takeBackPriceAttributeInformations}
            handleExtend={handleExtend}
            t={t}
            prismicShopData={doc?.data}
            open={open}
            priceRange={priceRange}
            rangeSelector={rangeSelector}
          />
        )}
      <div>
        {attributes.map((attributeFilter, attributeIndex) => {
          if (attributeFilter.type === AttributeType.CHECKBOX) {
            const value = selectedAttributes.find(
              (attribute) => attribute.code === attributeFilter.code
            )?.value
            return (
              <div
                key={attributeIndex}
                className={clsx(classes.filterListItem, classes.checkBoxValue)}
                onClick={() => handleToggleCheckbox(attributeFilter.code)}
              >
                <Checkbox
                  edge="start"
                  checked={!!value}
                  tabIndex={-1}
                  disableRipple
                />
                {attributeFilter.code}
              </div>
            )
          }
        })}
      </div>
      {/* Quality filters */}
      {parameters?.items?.enableTakeBack && isSecondHandTaxons && (
        <div>
          <ListItem
            className={classes.filterListItem}
            onClick={handleExtendQualities}
          >
            <Typography
              variant={'body1'}
              component={'div'}
              className={classes.attributeTitle}
            >
              <CMSText
                defaultText={t('texts:shop:states')}
                data={doc?.data?.states}
              />
            </Typography>
            {qualitiesList ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={qualitiesList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {['perfectCondition', 'veryGoodCondition', 'goodCondition'].map(
                (choice, index) => {
                  const currentAttributeCat = selectedAttributes
                    ?.find((selected) => selected.code === 'productCondition')
                    ?.values?.find((value) => value === choice)
                  return (
                    <div key={index}>
                      <AttributeListItem
                        code={'productCondition'}
                        attributeKey={choice}
                        handleToggle={handleToggle}
                        index={index}
                        name={
                          productCheckState(takebackText?.data)[
                            Object.keys(productCheckState(takebackText))?.find(
                              (key) => key === choice
                            )
                          ].title
                        }
                        checked={typeof currentAttributeCat !== 'undefined'}
                      />
                    </div>
                  )
                }
              )}
            </List>
          </Collapse>
        </div>
      )}
    </List>
  )
}

export default AttributesFilters
