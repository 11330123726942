import React from 'react'
import { ListItem, Collapse, List, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Slider from '@material-ui/core/Slider'
import { useStyles as useAttributesFiltersStyles } from './AttributesFilters'
import CMSText from '../Global/CMSText'
import { PrismicShopData } from '../../interfaces/common/prismicShop'
import { AttributeType, PriceAttribute } from '../../typings/filters'

type Tprops = {
  attributeFilter: PriceAttribute
  handleExtend: (attributeFilter: PriceAttribute, type: string) => void
  t: any
  prismicShopData: PrismicShopData
  open: any
  priceRange: { price: number[]; takeBackPrice: number[] }
  rangeSelector: any
}

const PricesFilter = ({
  attributeFilter,
  handleExtend,
  t,
  prismicShopData,
  open,
  priceRange,
  rangeSelector,
}: Tprops) => {
  const attributeType = attributeFilter.type
  const attributeCode = attributeFilter.code
  const classes = useAttributesFiltersStyles()
  return (
    <div key={attributeType}>
      <ListItem
        className={classes.filterListItem}
        onClick={() => handleExtend(attributeFilter, AttributeType.PRICE)}
      >
        <Typography
          variant={'body1'}
          component={'p'}
          className={classes.attributeTitle}
        >
          <CMSText
            defaultText={t('texts:shop:filter_price')}
            data={prismicShopData?.filter_price}
          />
        </Typography>
        {open.find((opened) => opened.value === attributeCode)?.open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItem>
      <Collapse
        in={open.find((opened) => opened.value === attributeCode)?.open}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <div className={classes.sliderContainer}>
            <Typography id="range-slider" gutterBottom>
              <CMSText
                defaultText={t('texts:shop:price_fork')}
                data={prismicShopData?.price_fork}
              />
            </Typography>
            <Slider
              value={priceRange[attributeType]}
              onChange={rangeSelector(attributeType)}
              min={attributeFilter.minimum / 100}
              valueLabelDisplay="auto"
              max={attributeFilter.maximum / 100}
            />
            <Typography id="range-slider" gutterBottom>
              <CMSText
                defaultText={t('texts:shop:between')}
                data={prismicShopData?.between}
                asText
              />{' '}
              {`${priceRange[attributeType][0]} ${t('texts:shop:and')} ${
                priceRange[attributeType][1]
              } ${attributeFilter.currency}`}
            </Typography>
          </div>
        </List>
      </Collapse>
    </div>
  )
}

export default PricesFilter
