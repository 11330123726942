import React from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  ListItem,
  Checkbox,
} from '@material-ui/core'
import clsx from 'clsx'

type Tprops = {
  checked: any
  handleToggle: any
  index: any
  code: string
  attributeKey: string
  name: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterListItem: {
      cursor: 'pointer',
      height: 35,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      justifyContent: 'space-between',
      fontSize: 14,
      borderBottom: `1px solid ${theme.extraColors.greyLight}`,
    },
  })
)

const AttributeListItem = ({
  handleToggle,
  checked,
  index,
  code,
  attributeKey,
  name,
}: Tprops) => {
  const classes = useStyles()
  return (
    <>
      <ListItem
        className={clsx(classes.filterListItem)}
        onClick={() => handleToggle(code, attributeKey, name)}
        style={{
          paddingLeft: '16px',
        }}
        key={index}
      >
        <div>
          <Checkbox
            edge="start"
            checked={checked}
            tabIndex={-1}
            disableRipple
          />
          {name}
        </div>
      </ListItem>
    </>
  )
}

export default AttributeListItem
