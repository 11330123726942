import React, { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import {
  Typography,
  Container,
  Grid,
  Fade,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { getTaxons } from '../redux/actions/taxons'
import { getTranslations } from '../i18n'
import { setFullLocale } from '../utils/utils'
import Layout from '../components/Layout/Layout'
import Commitment from '../components/Commitment/Commitment'
import Herosection from '../components/Hero/HeroSection'
import DateRangeForm from '../components/DateRangeForm/DateRangeForm'
import Catalog from '../components/Shop/Catalog'
import useShopStyles from '../components/Shop/Shop.style'
import Loader from '../components/Loader/Loader'
import CMSText, {
  isPrismicText,
  prismicText,
} from '../components/Global/CMSText'
import FilterDrawer from '../components/Filters/FilterDrawer'
import { findIfSlugExist } from '../utils/utils'
import {
  getProductsByTaxonsSlug,
  getCumulativeProductsByTaxonsSlug,
  setCurrentSlug,
  emptyProductState,
} from '../redux/actions/products'
import { createClient, getSingleDocument } from '../prismicio'
import { RootState } from '../redux/reducers/rootReducer'
import { InferGetStaticPropsType } from 'next'
import { GetMapDispatchTypes } from '../interfaces/common/redux'
import { PrismicPage } from '../interfaces/common/prismicPage'
import { PrismicShop } from '../interfaces/common/prismicShop'
import { SAVE_PRISMIC_DOCUMENTS } from '../redux/actions/prismic'
import { useDispatch } from 'react-redux'
import HrefLangLinks from '../components/HrefLangLinks'
import { PrismicGeneralConfig } from '../interfaces/common/prismicGeneralConfig'
import { isSecondHandOnly } from '../utils/constants'

const mapStateToProps = (state: RootState) => ({
  taxons: state.taxons,
  cart: state.cart,
  products: state.products,
  stock: state.stock,
  parameters: state.parameters,
})

const mapDispatchToProps = {
  getTaxons: getTaxons,
  getProductsByTaxonsSlug: getProductsByTaxonsSlug,
  getCumulativeProductsByTaxonsSlug: getCumulativeProductsByTaxonsSlug,
  setCurrentSlug: setCurrentSlug,
  emptyProductState: emptyProductState,
}

type TSlugProps = ReturnType<typeof mapStateToProps> &
  InferGetStaticPropsType<typeof getServerSideProps> &
  GetMapDispatchTypes<typeof mapDispatchToProps>

const Shop = ({
  getTaxons,
  taxons,
  doc,
  basket,
  takeback,
  inStoreText,
  main,
  funnel,
  classic_sub,
  emptyProductState,
  getProductsByTaxonsSlug,
  getCumulativeProductsByTaxonsSlug,
  cart,
  stock,
  parameters,
  setCurrentSlug,
  pageData,
  productSettings,
  products,
  accountRes,
  faqRes,
  banner,
}: TSlugProps) => {
  const router = useRouter()
  const theme = useTheme()
  const currentLocale = router?.locale ?? 'fr'
  const isSubscription =
    process.env.STORE_TYPE === 'subscription' ||
    process.env.STORE_TYPE === 'classic_subscription'
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  let category = router?.query?.slug
  const LayoutStyle = {
    isZwiterHandCategory:
      category?.length && ['second-hand', 'zwiter-hand'].includes(category[0]),
  }
  const classes = useShopStyles(LayoutStyle)
  const { t } = useTranslation()
  const { trackPageView } = useMatomo()
  const dispatch = useDispatch()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isLoadingProductsAndStocks, setIsLoadingProductsAndStocks] =
    useState<boolean>(true)
  const [fetchingTaxons, setFetchingTaxons] = useState(true)

  const currentPageFromQuery = router?.query?.currentPage
  const page = Array.isArray(currentPageFromQuery)
    ? currentPageFromQuery[0]
    : currentPageFromQuery || '1'

  const [selectedCategory, setSelectedCategories] = useState(0)
  const [currentPage, setCurrentPage] = useState(
    typeof page !== 'undefined' ? parseInt(page) : 1
  )
  const [sort, setSort] = useState<Record<string, 'ASC' | 'DESC'> | null>(null)
  const columnLayout = main?.data?.page_shop_layout
  const settings = main?.data
  const isMobileAccordion = doc?.data?.is_display_accordion_on_mobile_page
  const isRawFormattedText = doc?.data?.raw_formatted_shop_description
  const isEnableChooseStartingDate = parameters?.items?.enableChooseStartingDate

  const loadMore = doc?.data?.pagination_mode
  const productToLoadLimit =
    doc?.data?.product_limit_per_load !== null
      ? doc?.data?.product_limit_per_load
      : 48

  const hrefLangs = useMemo(
    () => main?.data?.href_languages?.[0]?.text?.split(',') ?? [],
    [main]
  )

  const isSecondHandTaxon =
    isSecondHandOnly === 'true'
      ? true
      : ['second-hand', 'zwiter-hand'].includes(category?.[0])

  useEffect(() => {
    dispatch(
      SAVE_PRISMIC_DOCUMENTS({
        general: main?.data,
        funnel: funnel?.data,
        basket: basket?.data,
      })
    )
  }, [funnel, main, basket])

  useEffect(() => {
    trackPageView({ documentTitle: 'Shop' })

    if (settings?.only_add_with_pack) {
      router.push('packs')
    }
  }, [])

  useEffect(() => {
    getTaxons()
    setFetchingTaxons(true)
  }, [currentLocale])

  useEffect(() => {
    if (taxons.loading === false) {
      setFetchingTaxons(false)
    }
  }, [taxons.loading])

  useEffect(() => {
    if (!fetchingTaxons && taxons?.items && taxons?.items.length) {
      if (category[0] === 'shop') {
        Array.isArray(category) && category.shift()
        router.push(Array.isArray(category) && category.join('/'))
      }
      const slugs = Array.isArray(category) ? [...category] : [category]

      if (!findIfSlugExist(taxons?.items[0], slugs)) {
        router.push('404')
      }
    }
  }, [taxons.items])

  useEffect(() => {
    if (taxons?.items?.length > 0) {
      setSelectedCategories(
        taxons?.items[0]?.children?.findIndex(
          (taxon) => taxon?.slug === category[0]
        )
      )
    }
  }, [taxons?.items])

  useEffect(() => {
    if (category?.length > 0) {
      if (typeof category === 'string') {
        category = [category]
      }
      if (products.currentSlug !== category[category.length - 1]) {
        emptyProductState()
      }
      const filtersStored = localStorage.getItem('Filters')
      if (loadMore) {
        if (products?.items?.items?.length === 0) {
          setCurrentSlug(category[category.length - 1])
          getCumulativeProductsByTaxonsSlug(
            category[category.length - 1],
            productToLoadLimit,
            currentPage,
            JSON.parse(filtersStored),
            null,
            null,
            isSecondHandTaxon,
            sort
          )
        }
      } else {
        setCurrentSlug(category[category.length - 1])
        getProductsByTaxonsSlug(
          category[category.length - 1],
          48,
          currentPage,
          JSON.parse(filtersStored),
          null,
          null,
          isSecondHandTaxon,
          sort
        )
      }
    }
  }, [products?.items?.items?.length])

  useEffect(() => {
    selectedCategory !== -1 &&
      localStorage.setItem('selectedCategory', selectedCategory?.toString())
  }, [selectedCategory])

  const heroInfo = useMemo(() => {
    // Parse data from the received page data (if there's a page with the current slug)
    return {
      title: prismicText(pageData?.data.title).toUpperCase(),
      // Check if a hero section has been added to the page, and get its background image
      image: pageData?.data?.body?.find(
        (el) => el.slice_type === 'hero_section'
      )?.primary?.background,
    }
  }, [pageData?.data])

  const handlePrev = () => {
    setCurrentPage(currentPage - 1)
    window?.scrollTo(0, 0)
    const pageNumber = currentPage - 1
    if (category?.length > 0) {
      if (typeof category === 'string') {
        category = [category]
      } else {
        category = [category.join('/')]
      }
    }
    router.push(
      {
        pathname: '/' + category,
        query: { currentPage: pageNumber },
      },
      undefined,
      { shallow: true }
    )
  }

  const handleNext = () => {
    setCurrentPage(currentPage + 1)
    window?.scrollTo(0, 0)
    const pageNumber = currentPage + 1
    if (category?.length > 0) {
      if (typeof category === 'string') {
        category = [category]
      } else {
        category = [category.join('/')]
      }
    }
    router.push(
      {
        pathname: '/' + category,
        query: { currentPage: pageNumber },
      },
      undefined,
      { shallow: true }
    )
  }

  const handleSort = (sort: Record<string, 'ASC' | 'DESC'>) => {
    setSort(sort)
    setCurrentPage(1)
  }

  const handleLoadMore = () => {
    const pageNumber = currentPage + 1
    setCurrentPage(pageNumber)
    if (category?.length > 0) {
      if (typeof category === 'string') {
        category = [category]
      }
      const filtersStored = localStorage.getItem('Filters')
      getCumulativeProductsByTaxonsSlug(
        category[category.length - 1],
        productToLoadLimit,
        pageNumber,
        JSON.parse(filtersStored),
        null,
        currentLocale,
        isSecondHandTaxon,
        sort
      )
    }
  }

  useEffect(() => {
    if (
      (!taxons.loading && !products.loading && !stock.loading) ||
      (stock.loading && products.isCumulative)
    ) {
      if (isLoadingProductsAndStocks) {
        setIsLoadingProductsAndStocks(false)
      }
    } else {
      if (!isLoadingProductsAndStocks) {
        setIsLoadingProductsAndStocks(true)
      }
    }
  }, [taxons.loading, products.loading, products.isCumulative, stock.loading])

  const ClaimTexts = () => (
    <Grid container spacing={isMobile ? 3 : 9}>
      <Grid item xs={12} md={6}>
        <>
          {isPrismicText(doc?.data?.titleshop) && (
            <Typography
              component="p"
              variant="h3"
              className="shop-claim__title"
            >
              <CMSText
                asText={!isRawFormattedText}
                data={doc?.data?.titleshop}
                defaultText={t('texts:shop:titleshop')}
              />
            </Typography>
          )}
          <Typography
            component={!isRawFormattedText ? 'p' : 'div'}
            variant="body1"
            className="shop-claim__desc"
          >
            <CMSText
              asText={!isRawFormattedText}
              data={doc?.data?.shopdescription}
              defaultText={t('texts:shop:shopdescription')}
            />
          </Typography>
        </>
      </Grid>
      <Grid item xs={12} md={6}>
        <Commitment
          desc={
            doc?.data?.shippingdescription ? (
              <CMSText data={doc.data.shippingdescription} />
            ) : (
              t('texts:shop:shippingdescription')
            )
          }
          icon={
            doc?.data?.shippingimage?.url ? doc?.data?.shippingimage?.url : null
          }
        />
      </Grid>
    </Grid>
  )

  return (
    <>
      <Layout
        mainLogo={main?.data?.logo}
        doc={main}
        t={t}
        inStoreText={inStoreText}
        metaTitle={
          (taxons?.items?.length > 0 &&
            taxons?.items?.[0]?.children?.[selectedCategory]?.name) ||
          prismicText(doc?.data?.meta_title_shop)
        }
        metaDescription={
          (taxons?.items?.length > 0 &&
            taxons?.items[0].children[selectedCategory]?.description) ||
          prismicText(doc?.data?.meta_description_shop)
        }
        metaImage={
          taxons?.items?.[0]?.children?.[selectedCategory]?.images.length
            ? taxons?.items?.[0]?.children?.[selectedCategory]?.images[0]
                .cachedPath
            : undefined
        }
        metaKeywords={doc?.data?.meta_keywords_shop}
        basket={basket}
        takeback={takeback}
        account={accountRes}
        faq={faqRes}
        banner={banner}
      >
        <HrefLangLinks locales={hrefLangs} />
        <main className={`main main--shop ${classes.root}`}>
          <Herosection
            title={
              heroInfo.title ||
              (!doc?.data?.use_taxon_title
                ? prismicText(doc?.data?.herotitle)
                : taxons?.items?.length > 0 &&
                  taxons?.items[0].children?.[
                    selectedCategory
                  ]?.name.toUpperCase())
            }
            isMainTitle
            topTitle={prismicText(doc?.data?.hero_top_title)}
            heroImg={heroInfo.image || main?.data?.hero_banner}
            heroSettings={main?.data}
            taxonImg={
              doc?.data?.use_taxon_image &&
              taxons?.items?.length > 0 &&
              taxons?.items[0]?.children?.[selectedCategory]?.images[0]
            }
            isDisplayAccordionOnMobile={isMobileAccordion}
          />
          <section className={clsx('shop__content', classes.shopContent)}>
            <Container maxWidth="xl" className={classes.shopContainer}>
              <div
                className={clsx(
                  'shop__planner',
                  classes.shopPlanner,
                  !isSubscription && classes.shopPlannerDad,
                  isSubscription && classes.noBoxShadow,
                  isSubscription && columnLayout && classes.shopPlannerColumn,
                  isSubscription &&
                    isMobile &&
                    isMobileAccordion &&
                    classes.shopPlannerMobileAccordion
                )}
              >
                {/* category[0] !== 'second-hand' only here for Vaude */}
                {(!isSubscription || isEnableChooseStartingDate) &&
                !['second-hand', 'zwiter-hand'].includes(category[0]) ? (
                  <DateRangeForm
                    isLanding={false}
                    t={t}
                    text={main?.data}
                    textFunnel={funnel?.data}
                    parameters={parameters}
                    scrollTarget={'catalog'}
                  />
                ) : (
                  !columnLayout &&
                  doc?.data?.has_shipping_card &&
                  (isMobile && isMobileAccordion ? (
                    <div className={classes.shopIntroMobileAccordion}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography component={'p'} variant="body1">
                            <CMSText
                              asText
                              data={doc?.data?.accordion_shop_title}
                              defaultText={t('texts:shop:accordion_shop_title')}
                            />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ClaimTexts />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ) : (
                    <ClaimTexts />
                  ))
                )}
              </div>
              <Container
                maxWidth="xl"
                className={clsx(
                  'shop__area',
                  classes.shopArea,
                  isSubscription && columnLayout && classes.shopAreaColumn
                )}
                data-stock={stock.loading ? 'loading' : 'loaded'}
              >
                {cart.loading && (
                  <div className={classes.loader}>
                    <Loader />
                  </div>
                )}
                {!isSubscription &&
                  !columnLayout &&
                  doc?.data?.has_shipping_card &&
                  category[0] !== 'second-hand' &&
                  (isMobile && isMobileAccordion ? (
                    <div
                      className={clsx(
                        classes.shopIntroMobileAccordion,
                        'shop__intro--mobile__accordion'
                      )}
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography component={'p'} variant="body1">
                            <CMSText
                              asText
                              data={doc?.data?.accordion_shop_title}
                              defaultText={t('texts:shop:accordion_shop_title')}
                            />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ClaimTexts />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ) : (
                    <div className={classes.shopIntro}>
                      <ClaimTexts />
                    </div>
                  ))}
                {typeof taxons.items !== 'undefined' &&
                  taxons.items.length > 0 && (
                    <>
                      {isLoadingProductsAndStocks && currentPage === 1 && (
                        <Fade in>
                          <div className={classes.loader}>
                            <Loader />
                          </div>
                        </Fade>
                      )}
                      <Catalog
                        taxons={taxons}
                        products={products}
                        loadMore={loadMore}
                        handleLoadMore={handleLoadMore}
                        setIsDrawerOpen={setIsDrawerOpen}
                        currentPage={currentPage}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                        handleSort={handleSort}
                        classic_sub_text={classic_sub}
                        shopData={doc}
                        isPack={
                          main?.data?.pack_page ? main?.data?.pack_page : false
                        }
                        settings={settings}
                        hidePrice={settings?.only_add_with_pack}
                        productSettings={productSettings}
                        basket={basket}
                        cart={cart}
                        takeback={takeback}
                        isDisplayedPrice={
                          main?.data?.is_product_price_displayed
                        }
                        isLoadingProductsAndStocks={isLoadingProductsAndStocks}
                        mainData={main}
                        sort={sort}
                      />
                    </>
                  )}
              </Container>
            </Container>
            {taxons?.items?.length > 0 && (
              <FilterDrawer
                setIsDrawerOpen={setIsDrawerOpen}
                isDrawerOpen={isDrawerOpen}
                doc={doc}
                t={t}
                currentPage={currentPage}
                takebackText={takeback}
              />
            )}
          </section>
        </main>
      </Layout>
    </>
  )
}

export async function getServerSideProps({ params, locale, previewData }) {
  const client = createClient({ previewData })
  const lang = setFullLocale(locale)

  let page: PrismicPage | null = null
  try {
    page = await client.getByUID<PrismicPage | null>('pages', params.slug?.[0])

    // eslint-disable-next-line no-empty
  } catch (e) {}

  const shop: PrismicShop | null = await getSingleDocument(client, lang, 'shop')
  const basket = await getSingleDocument(client, lang, 'basket')
  const general = await getSingleDocument(client, lang, 'general')
  const funnel = await getSingleDocument(client, lang, 'funnel')
  const takeback = await getSingleDocument(client, lang, 'take_back')
  const inStoreText = await getSingleDocument(client, lang, 'instore')
  const classic_sub = await getSingleDocument(
    client,
    lang,
    'classic_subscription_spec'
  )
  const accountRes = await getSingleDocument(client, lang, 'account')
  const faqRes = await getSingleDocument(client, lang, 'faq')
  const banner = await getSingleDocument(client, lang, 'banner')
  const productSettings = await getSingleDocument(
    client,
    lang,
    'single_product'
  )

  return {
    props: {
      takeback,
      doc: shop,
      basket,
      main: general as PrismicGeneralConfig,
      productSettings,
      pageData: page,
      inStoreText,
      funnel,
      accountRes,
      faqRes,
      classic_sub,
      banner,
      i18nResources: await getTranslations(locale, ['texts']),
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop)
