import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  ListItem,
  Collapse,
  List,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import AttributeListItem from './AttributeListItem'
import { useMatomo } from '@datapunt/matomo-tracker-react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterListItem: {
      cursor: 'pointer',
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      justifyContent: 'space-between',
      fontSize: 14,
      height: 35,
      borderBottom: `1px solid ${theme.extraColors.greyLight}`,
      '&:active': {
        backgroundColor: theme.extraColors.greyLight,
      },
    },
    attribute_list: {
      padding: 0,
    },
    sliderContainer: {
      width: '100%',
      padding: theme.spacing(2),
    },
    checkBoxValue: {
      paddingLeft: theme.spacing(2),
      textTransform: 'none',
    },
    attributeTitle: {
      fontSize: 14,
      lineHeight: '10px',
    },
  })
)

type Tprops = {
  sizes: any
  setSelectedAttributes: any
  selectedAttributes: any
}

const SizesFilters = ({
  sizes,
  setSelectedAttributes,
  selectedAttributes,
}: Tprops) => {
  const classes = useStyles()
  const [open, setOpen] = useState([])
  const { trackEvent } = useMatomo()

  useEffect(() => {
    if (selectedAttributes?.length > 0) {
      const tempOpen = []
      sizes.map((size, index) => {
        const selectedValues = selectedAttributes.find(
          (selectedAttribute) => selectedAttribute.code === 'size'
        )?.values
        selectedValues?.map((value) => {
          if (size.sizes.find((listItem) => listItem.code === value)) {
            tempOpen.push({ value: index, open: true })
          }
        })
      })
      setOpen(tempOpen)
    }
  }, [])

  // extand the attribute list
  const handleExtend = (attributeIndex) => {
    const tempOpen = [...open]
    if (tempOpen.find((isopened) => isopened.value === attributeIndex)) {
      tempOpen.splice(
        tempOpen.findIndex((isopened) => isopened.value === attributeIndex),
        1
      )
    } else {
      tempOpen.push({ value: attributeIndex, open: true })
    }
    setOpen(tempOpen)
  }

  // select an attribute in the list
  const handleToggle = (code, key) => {
    const tempSelectedAttributes = [...selectedAttributes]
    const isCodeSelected = tempSelectedAttributes?.find(
      (isSelected) => isSelected.code === code
    )
    if (isCodeSelected) {
      const selectedCategorieIndex = isCodeSelected?.values?.findIndex(
        (value) => value === key
      )
      if (selectedCategorieIndex >= 0) {
        tempSelectedAttributes
          .find((isSelected) => isSelected.code === code)
          .values.splice(selectedCategorieIndex, 1)
      } else {
        tempSelectedAttributes
          ?.find((isSelected) => isSelected.code === code)
          .values?.push(key)
      }
    } else {
      tempSelectedAttributes.push({ code: code, values: [key] })
    }
    trackEvent({
      category: 'Shop',
      action: `User select size ${key}`,
    })
    setSelectedAttributes(tempSelectedAttributes)
  }

  return (
    <List className={classes.attribute_list}>
      {sizes.map((sizeFilter, sizeIndex) => {
        return (
          <div key={sizeIndex}>
            <ListItem
              className={classes.filterListItem}
              onClick={() => handleExtend(sizeIndex)}
            >
              <Typography
                variant={'body1'}
                component={'div'}
                className={classes.attributeTitle}
              >
                {sizeFilter.name}
              </Typography>
              {open.find((opened) => opened.value === sizeIndex)?.open ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItem>
            <Collapse
              in={open.find((opened) => opened.value === sizeIndex)?.open}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {sizeFilter.sizes?.map((choice, index) => {
                  const currentAttributeCat = selectedAttributes
                    ?.find((selected) => selected.code === 'size')
                    ?.values?.find((value) => value === choice.code)
                  return (
                    <div key={index}>
                      <AttributeListItem
                        code={'size'}
                        attributeKey={choice.code}
                        handleToggle={handleToggle}
                        index={index}
                        name={choice.name}
                        checked={typeof currentAttributeCat !== 'undefined'}
                      />
                    </div>
                  )
                })}
              </List>
            </Collapse>
          </div>
        )
      })}
    </List>
  )
}

export default SizesFilters
