import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useFilterDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      '& .MuiPaper-root': {
        width: 400,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
    },
    drawerContainer: {
      width: '100%',
      backgroundColor: theme.palette.common.white,
      height: '100%',
    },
    header: {
      height: 50,
      width: 400,
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 30,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
      padding: theme.spacing(2),
      borderBottom: `1px solid ${theme.extraColors.greyLight}`,
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    subItem: {
      paddingLeft: theme.spacing(2),
    },
    footer: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: 400,
      height: 50,
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.common.white,
      alignContent: 'center',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    return_btn: {
      margin: theme.spacing(1),
    },
    filterListItem: {
      marginTop: 50,
      cursor: 'pointer',
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      justifyContent: 'space-between',
      display: 'flex',
      alignContent: 'center',
      fontSize: 14,
      borderBottom: `1px solid ${theme.extraColors.greyLight}`,
      '&:active': {
        backgroundColor: theme.extraColors.greyLight,
      },
    },
    breadcrumb: {
      backgroundColor: theme.palette.common.white,
      fontSize: 14,
      display: 'flex',
      borderBottom: `1px solid ${theme.extraColors.greyLight}`,
    },
    loaderContainer: {
      height: 200,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeFilter: {
      fontSize: 14,
    },
    activeFilterText: {
      fontWeight: 700,
    },
    taxonTitle: {
      fontSize: 14,
      lineHeight: '10px',
    },
    margin: {
      marginBottom: theme.spacing(3),
    },
    resetButton: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      color: theme.extraColors.greyText,
      fontSize: 12,
      fontWeight: 400,
      width: '50%',
      whiteSpace: 'nowrap',
      '& .MuiSvgIcon-root': {
        width: '.75em',
        height: '.75em',
        marginRight: theme.spacing(1),
      },
    },
    applyButton: {
      fontSize: 12,
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      width: '50%',
    },
    filterContainer: {
      marginTop: 50, // Filter Drawer Header
    },
    filterBy_text: {
      color: theme.extraColors.greyText,
      fontSize: 10,
      marginLeft: theme.spacing(1),
    },
    transition: {
      animation: 'fadeIn 1s',
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    help: {
      cursor: 'pointer',
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      marginLeft: -theme.spacing(1),
    },
  })
)
